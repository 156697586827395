import React, { Fragment } from 'react';
import { Typography, Grid } from '@material-ui/core';

const Contact = (props) => {

    return <Fragment>
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h5" component="h2" style={{marginTop: 5}}>
                    Please fill in the contact form below
                </Typography>
                <Typography color="textSecondary" style={{marginBottom: 5}}>
                    we'll try help as best we can
                </Typography>
                <iframe title="contact_form" src="https://docs.google.com/forms/d/e/1FAIpQLSfhOnim58E918pdEUq3L_Su7KXUaVoe7GB5o_tOgcrHKRjCyA/viewform?embedded=true" width="640" height="650" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </Grid>
        </Grid>        
    </Fragment>

}

export default Contact