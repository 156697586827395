import React from 'react';
import { Grid, Typography, Button, Card, CardContent, CardMedia } from '@material-ui/core';

const About = () => {

    const handleClick = (page) => () => {
        switch (page) {
            case "get-started":
                window.location = window.location.origin+"/?predict"       
                break;
            case "faq":
                window.location = window.location.origin+"/?faq"       
                break;
            case "contact":
                    window.location = window.location.origin+"/?contact"       
                    break;
            default:
                break;
        }        
    }

    return <Grid container>
        <Grid item xs={11} md={5} style={{margin: 10}}>
            <Card style={{margin: 10}}>
                <CardMedia
                    style={{width:"100%", height: "30vh"}}
                    image="/img/tallinn-skyline.jpg"
                    title="Tallinn Skyline"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        About
                    </Typography>
                    <Typography color="textSecondary" style={{paddingBottom: 5}}>
                        The motivation behind this site
                    </Typography>
                    <Typography variant="body2" color="black" component="p" style={{textAlign: "left"}}>
                        <p>
                            Estonia rightfully earns it's reputation as a digitally advanced nation. In the real estate sector this is no different, 
                            and a couple of extremly useful sites will make property hunting very easy:
                                <ul>
                                    <li>
                                        <a href="http://kv.ee">
                                            KV.ee
                                        </a>
                                    </li>
                                    <li>
                                        <a href="http://www.city24.ee">
                                            City24
                                        </a>
                                    </li>
                                </ul>
                            That said, these sites are geared towards simply finding a property. Once you've bought or chosen a property you may have 
                            questions such as what constitutes a fair price for your property, and what are similar properties so you can make a better
                            informed decision when it comes both to buying and selling your property.
                        </p>
                        <p>
                            We faced these same questions ourselves, and we've been successfully using the algoithm powering this site to answer questions
                            similar to those above ever since.
                        </p>                        
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12} md={1} />
        <Grid item xs={11} md={5} style={{margin: 10}}>
            <Card style={{margin: 10}}>
            <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        Uses
                    </Typography>
                    <Typography color="textSecondary" style={{paddingBottom: 5}}>
                        Why use the Tallinn Property Predictor?
                    </Typography>
                    <Typography variant="body2" color="black" component="p" style={{textAlign: "left"}}>
                        <strong>For apartment sellers or property investors:</strong>
                            <ul>
                                <li> How much is my property worth in the current market? </li>                                
                                <li> Which are similar properties to the one I am selling? What's my likely competition?</li>
                                <li> Is my property investment increasing over time? </li>
                            </ul>
                        <strong>For apartment buyers:</strong>
                            <ul>
                                <li> Is the seller charging a fair price? </li>                                
                                <li> Which are similar properties to the one I am buying? What are my best alternatives?</li>
                            </ul>                            
                    </Typography>
                </CardContent>
            </Card>
            <Card style={{margin: 10}}>
            <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        Getting Stared - It's Free!
                    </Typography>
                    <Typography color="textSecondary" style={{paddingBottom: 5}}>
                        3 easy steps
                    </Typography>
                    <Typography variant="body2" color="black" component="p" style={{textAlign: "left"}}>
                        
                        <ol>
                            <li> Click the "Get Stared" button below and click on the map to select the position of your apartment </li>                                
                            <li> Click "Next" and on the next screen fill in the required information such as year built, number of rooms, and so on</li>
                            <li> Click "Submit" and view your results!</li>
                        </ol>
                        
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleClick('contact')} style={{marginRight: 15}}>
                Contact
            </Button>
            <Button variant="contained" color="primary" onClick={handleClick('faq')} style={{marginRight: 15}}>
                FAQs
            </Button>
            <Button variant="contained" color="primary" onClick={handleClick('get-started')}>                
                Get Started >>>
            </Button>
        </Grid>
    </Grid>
}

export default About;