import React, {useState, Fragment} from 'react';
import './App.css';
import {Container, Grid, Divider} from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TallinnMap from './components/TallinnMap';
import AdditionalDetails from './components/AdditionalDetails';
import Axios from 'axios';
import Results from './components/Results';
import About from './components/About';
import FAQ from './components/Faq';
import Contact from './components/Contact';

const App = () => {
  const [data, changeData] = useState({});
  const [results, changeResults] = useState({});
  const [activeStep, changeActiveStep] = useState(0);
  const steps = ['Select Desired Area', 'Choose Additional Details', 'View Your Results'];

  const handleReset = () => {
    changeActiveStep(0);
    changeData({});
  };
  const handleBack = () => {

    if (activeStep===0){
      window.location=window.location.origin;
    } else {
      changeActiveStep(activeStep-1);
    }
    
  };
  const handleNext = () => {
    const nextStep = activeStep+1;
    changeActiveStep(nextStep);
    if (nextStep === 2){
      Axios.post('//davevassallo.eu.pythonanywhere.com/predict', data).then((resp)=>{
        console.log(resp.data);      
        changeResults(resp.data)  ;
      })
    }
  };

  const handleDataChange = (newData) => {
    const updatedData = {...data, ...newData};
    changeData(updatedData);
  }

  const getMainContent = () => {
    const hash = window.location.search;
    switch (hash) {
      case "":
        return <About />
      case "?contact":
          return <Contact />
      case "?faq":
        return <FAQ />
      case "?predict":
        return <Fragment>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography >
                  All steps completed - you&apos;re finished. Please fill in the below feedback form!
                </Typography>
                <Divider />
                <Button onClick={handleReset} variant="contained" color="primary">
                  Reset
                </Button>
              </div>
            ) : (
              <div>
                <Typography>
                  {getStepContent(activeStep)}
                </Typography>
                <div>
                  <Button onClick={handleBack} variant="contained" color="primary" style={{margin: 10}}>
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    style={{margin: 10}}
                    disabled={ (activeStep === 0 && !data.lat) 
                      || (activeStep === 1 && data.deal_type === 1 && !(data.area && data.condition && data.year && data.rooms))
                      || (activeStep === 1 && data.deal_type === 2 && !(data.area && data.condition && data.rooms))
                    } //&& data.floor && data['total-floors']))}
                  >
                    {activeStep === 0 ? 'Next' : null}
                    {activeStep === 1 ? 'Sumbit' : null}
                    {activeStep === 2 ? 'Finish' : null}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Fragment>
      default:
        break;
    }
  }

  const getStepContent = (step_index) => {

    let content = <div />;
    switch (step_index) {
      case 0:
        content = <TallinnMap handleDataChange={handleDataChange} />;
        break;
      case 1:
          content = <AdditionalDetails handleDataChange={handleDataChange} />;
          break;
      case 2:
          content = <Results data={results} />;
          break;
      default:
        break;
    }

    return content;
  }

  return (
    <div className="App">
      <Container>
        <Grid container>
          <Grid item xs={12} style={{margin: 10}}>
            <Typography variant="h2">
              Tallin Property Predictor
            </Typography>
          </Grid>
        </Grid>
        {getMainContent()}
        {/* <div>
          {JSON.stringify(data)}
        </div> */}
      </Container>
    </div>
  );
}

export default App;
