import React, { createRef, useState, useEffect, Fragment } from 'react'
import { Map, TileLayer } from 'react-leaflet'
import { Typography } from '@material-ui/core';
import ExtendedMarker from './ExtendedMarker';

const TallinnMap = (props) => {
    
    const [hasLocation, changeHasLocation] = useState(false);
    const [lat, changeLat] = useState(59.43741643648735);
    const [lng, changeLng] = useState(24.745158375451314);
    const [marker, changeMarker] = useState();

    const mapRef = createRef()

    const handleClick = (e) => {
      console.log(e);
      // const map = mapRef.current
      // if (map != null) {
      //   map.leafletElement.locate()
      // }
      changeHasLocation(true);
      changeLat(e.latlng.lat);
      changeLng(e.latlng.lng);
      props.handleDataChange({
        lat: Math.round(e.latlng.lat*10000000)/10000000,
        lng: Math.round(e.latlng.lng*10000000)/10000000
      })

    }

    const handleLocationFound = (e) => {
        changeHasLocation(true);
        changeLat(e.latlng.lat);
        changeLng(e.latlng.lng);
    }

    useEffect(()=>{
        if (hasLocation) {
            changeMarker(
              <ExtendedMarker lat={lat} lng={lng} />                
            )
        }        
    },[hasLocation, lat, lng])
    

    return (
      <Fragment>
        <Typography variant="overline">
            Tip: zoom in and out to more accurately clik on the map and place your marker
        </Typography>  
        <Map
          center={{lat: lat, lng: lng}}
          length={4}
          onClick={handleClick}
          onLocationfound={handleLocationFound}
          ref={mapRef}
          zoom={13}>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {marker}
        </Map>
      </Fragment>      
    )
  
}

export default TallinnMap;
