import React, { Fragment } from 'react';
import { Grid, Typography, Button, Card, CardContent } from '@material-ui/core';
import { HorizontalBar } from 'react-chartjs-2';

const FAQ = () => {

    const handleClick = () => {
        window.location = window.location.origin+"/"
    }

    const windowHash = window.location.hash;
    const saleData = {
        labels: ['Area', 'Position', 'Year', 'Rooms', 'Condition'],
        datasets: [
          {
            label: 'Sale Feature Importance',
            backgroundColor: '#64E0FF',
            borderColor: '#00CCFF',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: [0.8, 0.08, 0.066, 0.03, 0.0075]            
          }
        ]
      };

    const rentData = {
        labels: ['Area','Position', 'Condition', 'Rooms'],
        datasets: [
          {
            label: 'Rent Feature Importance',
            backgroundColor: '#64E0FF',
            borderColor: '#00CCFF',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: [0.79, 0.148, 0.03, 0.026]            
          }
        ]
      };

    const chartOptions = {
        scales: {
            xAxes: [{
                ticks: {
                    suggestedMax: 1,
                    suggestedMin: 0
                }
            }]
        }
    }

    const content = [
        [
            '#results', 
            'The predictions I got do not match my expectations - how do you calculate your predictions?', 
            <Fragment>
                <p>
                    Our algorithm leverages a couple of machine learning algorithms. We don't depend on the usual statistics such as averages. Instead, 
                    we gather a lot of real-world data about properties advertised in the Estonian market. Then we show our algorithm this data and ask 
                    it to learn the important features on it's own. That's the biggest difference between this site and your normal property sites. 
                </p>
                <p>
                    That means the algorithm learns the importance of the features on it's own. The importance of these features change over time, but here's 
                    a sample of the feature importance for apartment sale and rent data:
                    
                    <HorizontalBar height={window.innerHeight*0.1} data={saleData} options={chartOptions} />
                    <br />
                    <HorizontalBar height={window.innerHeight*0.1} data={rentData} options={chartOptions} />
                    <br/>                   
                </p>
                <p>
                    Notice that in both cases as expected the "Area" of a property is the biggest decisive fctor when predicting price.
                    What's interesting is that while the "Position" of the property comes in second, it relatively is not given that much importance, 
                    having similar importance to "condition" or "number of rooms".
                    So while realtors like to say "Location, Location, Location" the data doesn't quite seem to support that... at least in the current market
                    in Tallinn where it seems most of the time buyers and renters are willing to pay for more area regardless of position.
                </p>
                <p>
                    So... when you go over your predictions, keep in mind that our algorithms do react according to market changes, and they
                    tend to suggest those properties and prices which have similar area to that which you are asking for rather than focusing on position.
                </p>                
            </Fragment>
        ]
    ]

    


    return <Grid container>        
            <Grid item xs={12} style={{margin: 10}}>
                <Typography gutterBottom variant="h3">
                    FAQs
                </Typography>
            </Grid>
            {content.map( elem => {
                const backgroundColor = windowHash === elem[0] ? "lightblue" : "white";
                return <Grid item xs={12} style={{margin: 10}}>
                    <Card id={elem[0]} style={{margin: 10, backgroundColor: backgroundColor}}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" style={{textAlign: "left"}}>
                                {elem[1]}
                            </Typography>
                            <Typography variant="body2" color="black" component="p" style={{textAlign: "left"}}>
                                {elem[2]}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            })}
                    
        <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleClick} style={{margin: 10}}>
                Home
            </Button>
        </Grid>
    </Grid>
}

export default FAQ;