import React, { useState } from 'react';
import { TextField, InputLabel, Select, MenuItem, FormControl, Typography, Grid } from '@material-ui/core';

const AdditionalDetails = (props) => {

    const [condition, changeCondition] = useState()
    const [deal_type, changeDealType] = useState()

    const handleChange = (changeType) => (e) => {
        switch (changeType) {
            case 'year':
            case 'area':
            case 'floor':
            case 'total-floors':
            case 'rooms':
                props.handleDataChange({
                    [changeType]: e.target.value
                })
                break;
            case 'condition':
                props.handleDataChange({
                    condition: e.target.value
                })
                changeCondition(e.target.value)
                break;       
            case 'deal_type':
                props.handleDataChange({
                    deal_type: e.target.value
                })
                changeDealType(e.target.value)
                break;   
            default:
                break;
        }
    }

    return <div>
        <Typography variant="overline" style={{color: 'red'}}>
            All fields required
        </Typography>
        <Grid container>
            <Grid item xs={11} md={3} />
            <Grid item xs={12} md={6}>
                <div style={{marginBottom: 10}}>
                    <FormControl variant="outlined" style={{ width: '100%'}}>
                        <InputLabel htmlFor="condition">Sale Or Rent?</InputLabel>
                        <Select
                        required
                        onChange={handleChange('deal_type')}
                        style={{ width: '100%'}}
                        inputProps={{
                            name: 'deal_type',
                            id: 'deal_type',
                        }}
                        value={deal_type}
                        >
                            <MenuItem value={1}>Apartment Sale</MenuItem>
                            <MenuItem value={2}>Apartment Rent</MenuItem>                            
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <FormControl variant="outlined" style={{ width: '100%'}}>
                        <InputLabel htmlFor="condition">Condition</InputLabel>
                        <Select
                        required
                        onChange={handleChange('condition')}
                        style={{ width: '100%'}}
                        inputProps={{
                            name: 'condition',
                            id: 'condition',
                        }}
                        value={condition}
                        >
                            <MenuItem value={7}>Development / Brand New</MenuItem>
                            <MenuItem value={6}>Good Condition</MenuItem>
                            <MenuItem value={5}>Renovated</MenuItem>
                            <MenuItem value={4}>Sanitary Renovation Done</MenuItem>
                            <MenuItem value={3}>Satisfactory</MenuItem>
                            <MenuItem value={2}>Sanitary Renovation Required</MenuItem>
                            <MenuItem value={1}>Full Renovation Required</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {deal_type === 2 ? 
                    <div /> 
                    : 
                    <div>
                        <FormControl variant="outlined">
                            <TextField
                                required
                                style={{ width: '100%'}}
                                id="standard-number"
                                label="Year of Construction"
                                onChange={handleChange('year')}
                                type="number"
                                margin="normal"
                                disabled={deal_type===2}
                            />
                        </FormControl>
                    </div>
                }                
                <div>
                    <FormControl variant="outlined">
                        <TextField
                            required
                            style={{ width: '100%'}}
                            id="area"
                            label="Area (m²)"
                            onChange={handleChange('area')}
                            type="number"
                            margin="normal"
                        />
                    </FormControl>
                </div>
                <div>
                    <FormControl variant="outlined">
                        <TextField
                            required
                            style={{ width: '100%'}}
                            id="rooms"
                            label="# of Bedrooms"
                            onChange={handleChange('rooms')}
                            type="number"
                            margin="normal"
                        />
                    </FormControl>
                </div>
                {/* <div>
                    <FormControl variant="outlined" style={{marginRight: 5}}>
                        <TextField  
                            required
                            style={{ width: '100%'}}
                            id="floor"
                            label="Floor"
                            onChange={handleChange('floor')}
                            type="number"
                            margin="normal"
                        />
                    </FormControl>
                    <FormControl variant="outlined">
                        <TextField
                            required
                            style={{ width: '100%'}}
                            id="total-floors"
                            label="Total Building Floors"
                            onChange={handleChange('total-floors')}
                            type="number"
                            margin="normal"
                        />
                    </FormControl>
                </div> */}
            </Grid>
            <Grid item xs={11} md={3} />
        </Grid>
        
    </div>

}

export default AdditionalDetails;