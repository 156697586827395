import React, { Fragment } from 'react';
import { Typography, Card, Grid, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';

const Results = (props) => {

    return <Fragment>
        <Grid container>
            <Grid item xs={11} md={6}>
                <Card style={{margin: 10}}>
                    <Typography variant="h5" component="h2">
                    Predicted Price
                    </Typography>
                    <Typography color="textSecondary">
                        based on similar properties. 
                        <br />
                        <a style={{fontSize: "x-small"}} href="/?faq"> Not what you expected? </a>
                    </Typography>
                    <Typography variant="h4">                        
                        <strong>
                            &euro; {props.data['price prediction'] ? Math.round(props.data['price prediction']) : 'Loading'}
                        </strong>
                    </Typography>
                </Card>
            </Grid>
            <Grid item xs={11} md={6}>
                <Card style={{margin: 10}}>
                    <Typography variant="h5" component="h2">
                    Similar Properties
                    </Typography>
                    <Typography color="textSecondary">
                        along with their similarity score.
                        <br />
                        <a style={{fontSize: "x-small"}} href="/?faq"> Not what you expected? </a>
                    </Typography>
                    <Paper>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Similarity Score</TableCell>
                                    <TableCell>Link To Property</TableCell>                                    
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.data['similar properties'] ? props.data['similar properties'].map(elem => {
                                    const score = Math.round(elem[0]*100)
                                    let score_color = 'gray'
                                    if (score <= 29){
                                        score_color = '#FF3333'
                                    } else if (score >= 30 && score <= 59){
                                        score_color = "#FF9633"
                                    } else if (score >= 60 && score <= 75){
                                        score_color = "#3EFFAB"
                                    } else if (score > 75){
                                        score_color = "#31C438"
                                    }

                                    return <TableRow>
                                        <TableCell style={{color: score_color}}>{score} %</TableCell>
                                        <TableCell>
                                            <a href={elem[1]}>{elem[1].split('/')[3].split('.html')[0]}</a>
                                        </TableCell>
                                    </TableRow>
                                }): <TableRow>
                                <TableCell>Loading...</TableCell>
                            </TableRow>}
                            </TableBody>
                        </Table>
                    </Paper>                        
                </Card>                
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5" component="h2" style={{marginTop: 5}}>
                    We'd appreciate your feedback!
                </Typography>
                <Typography color="textSecondary" style={{marginBottom: 5}}>
                    it will help the site evolve
                </Typography>
                <iframe title="feedbackform" src="https://docs.google.com/forms/d/e/1FAIpQLSeIJtW9tVKvI0IgZmVuJm3FXua5XTH5KXGhqRMvl5iqlVQQYA/viewform?embedded=true" width="640" height="1053" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </Grid>
        </Grid>        
    </Fragment>

}

export default Results