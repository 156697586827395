import React, { useRef, useEffect } from 'react'
import { Marker, Popup } from 'react-leaflet'

const ExtendedMarker = (props) => {
    
    const markerRef = useRef(null);

    useEffect(()=>{
        if (markerRef != null){
            markerRef.current.leafletElement.openPopup();
        }
    });

    return (<Marker ref={markerRef} position={{lat: props.lat, lng: props.lng}}>
        <Popup>
                You've chosen: {Math.round(props.lat*100000)/100000}, {Math.round(props.lng*100000)/100000}
            <hr/>
            <i>
                Please click the "Next" button below
            </i>
        </Popup>
    </Marker>)
}

export default ExtendedMarker